.about-us {
  padding: 2rem 0;
  background-color: #f8f9fa;
}

.about-us h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-us p {
  font-size: 1.2rem;
  line-height: 1.5;
}

.reasons-to-work-together {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.info-card {
  width: 100%; /* Make cards responsive to width */
  max-width: 350px; /* Limit maximum width of cards */
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
  color: #333;
}

.info-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

.info-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-card .card-text {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .info-card {
      max-width: 100%; /* Full width on smaller screens */
  }
}
