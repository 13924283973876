/* Register.css */
/* Container Reset */
.register-container {
  padding: 2rem 0; /* Remove horizontal padding */
  min-height: calc(100vh - 56px); /* Subtract navbar height */
  /* background-color: #f8f9fa; */
  width: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Card Containment */
.register-card {
  background: white;
  border-radius: 12px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  margin: 0 auto 2rem;
  border: none;
  max-width: 100%; /* Ensure card doesn't overflow */
}

.register-card .card-body {
  padding: 2rem;
}

.register-card h3 {
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 500;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.form-control {
  border-radius: 8px;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  transition: all 0.3s ease;
  width: 100%; /* Ensure inputs don't overflow */
  max-width: 100%; /* Prevent input overflow */
  box-sizing: border-box; /* Include padding in width calculation */
}

.form-control:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.form-control::placeholder {
  color: #a0aec0;
}

.required {
  color: #e53e3e;
  margin-left: 4px;
}

.btn-primary {
  background-color: #4299e1;
  border: none;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 1rem;
  width: 100%;
  max-width: 100%;
}

.btn-primary:hover {
  background-color: #3182ce;
  transform: translateY(-1px);
}

.btn-primary:active {
  transform: translateY(0);
}

.alert {
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
  box-sizing: border-box;
}

.alert-success {
  background-color: #c6f6d5;
  border-color: #9ae6b4;
  color: #2f855a;
}

.alert-danger {
  background-color: #fed7d7;
  border-color: #feb2b2;
  color: #c53030;
}

/* Column Adjustments */
.col-md-8.col-lg-6 {
  padding: 0 15px; /* Consistent padding */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .register-container {
    padding: 1rem 0;
  }

  .register-card .card-body {
    padding: 1.25rem;
  }

  .form-control {
    padding: 0.625rem 0.875rem;
  }

  .btn-primary {
    padding: 0.625rem 1.25rem;
  }

  /* Ensure container aligns with navbar */
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 576px) {
  .register-container {
    padding: 0.5rem 0;
  }

  .register-card {
    border-radius: 8px;
    margin: 0 10px 1rem; /* Add horizontal margin */
  }

  .register-card .card-body {
    padding: 1rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .form-label {
    font-size: 0.875rem;
  }

  /* Adjust column padding for mobile */
  .col-md-8.col-lg-6 {
    padding: 0 10px;
  }
}

/* Form Group Spacing */
.form-group {
  margin-bottom: 1.5rem;
}

/* Select Element Styling */
select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M8 11L3 6h10l-5 5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}

/* Hover States */
.form-control:hover {
  border-color: #cbd5e0;
}

/* Focus States */
.form-control:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

/* Disabled States */
.form-control:disabled,
.form-control[readonly] {
  background-color: #edf2f7;
  opacity: 0.7;
}

/* Row Adjustment */
.row.justify-content-center {
  margin: 0; /* Remove default row margin */
  width: 100%;
}

.btn-primary {
  background-color: #007bff; /* Default primary color */
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3 !important; /* Darker shade */
  border-color: #004085 !important;
}
