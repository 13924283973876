/* Home.css */

.sl-main-banner__content {
    position: absolute;
    top: 20%;
    left: 5%;
    color: white;
  }
  
  .sl-main-banner__btn .sl-btn {
    margin-right: 10px;
  }
  
  .most-visited-categories {
    padding: 50px 0;
    text-align: center;
  }

  .services-cat>h2{
    font-size: 40px;
      }

  .services-cat>p{
font-size: 20px;
padding-bottom: 40px;
  }

  .category-card{
    width: 160px;
    height: 160px;
    padding: 25px 10px;
  }
  
  .category-card .category-icon {
    width: 64px;
    height: 64px;
    margin: 0 auto;
  }
  .cardBody{
    padding:10px;
  }
  
  .title{
    font-size: 12px;
    color: black;
  }
  .category-card .description {
    display: none;
  }
  
  .category-card:hover .description {
    display: block;
  }

  @media (max-width: 992px) {
    .category-card {
      width: 160px;
      height: 160px;
      padding: 30px 8px;
    }
    .title {
      font-size: 13px;
    }
  }
  
  @media (max-width: 768px) {
    .category-card {
      width: 135px;
      height: 135px;
      padding: 17px 6px;
    }
    .category-card .category-icon {
        width: 44px;
        height: 44px;
        margin: 0 auto;
      }
      
    .title {
        
      font-size: 12px;
    }
  }
  
  @media (max-width: 576px) {
    .category-card {
      width: 120px;
      height: 120px;
      padding: 20px 6px;
    }
    .title {
      font-size: 11px;
    }
  }
  /* Home.css */

  .banner-section .full-width-container {
    position: relative;
  }
  
  .banner-section .w-100 {
    width: 100%;
    /* height: 220px; */
    height: auto;
  }
  
  .banner-section .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .banner-section .banner-content h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .banner-section .banner-content p {
    font-size: 1.5rem;
    /* margin-bottom: 2rem; */
  }
  
  .banner-section .button-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .banner-section .banner-button {
   width: 150px;
    font-size: 1.2rem;
    padding: 0.4rem 1.8rem;
    border-radius: 0.5rem;
    
  }
  

  .banner-section .banner-button:hover {
    color: black;
    background-color: white;
  }
  /* .banner-section .banner-button:first-of-type {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .banner-section .banner-button:first-of-type:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .banner-section .banner-button:last-of-type {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .banner-section .banner-button:last-of-type:hover {
    background-color: #5a6268;
    border-color: #545b62;
  } */
  
/* .banner-section {
    position: relative;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    overflow: hidden;
  }
  
  .full-width-container {
    width: 100%;
    position: relative;
  }
  
  .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .banner-content h2,
  .banner-content p {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0;
    padding: 10px;
  }
  
  .banner-content p {
    margin-top: 10px;
  }
  
  .banner-content .btn {
    height: 40px;
    width: 200px;
    margin-top: 20px;
  } */
  
  
  .top-service-providers {
    padding: 50px 0;
    text-align: center;
  }
  

  .hero-section {
    position: relative;
    width: 100%;
    height: 40vh; /* Adjust height as needed */
    overflow: hidden;
  }
  
  /* Video */
  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  /* Text Overlay */
  .hero-text {
    width: 70vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; /* Text color */
    z-index: 1;
  }
  
  /* Additional styling for text */
  .hero-text h1 {
    font-size: 3.4rem; /* Adjust font size */
    font-weight: 900;
    margin-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .hero-text p {
    font-size: 1.5rem; /* Adjust font size */
    margin-bottom: 2rem;
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .hero-text{
      width: 80vw;
    }
    .hero-text h1 {
      font-size: 2.5rem; /* Adjust font size */
    }
  
    .hero-text p {
      font-size: 1.2rem; /* Adjust font size */
    }
  }


  @media (max-width: 576px) {
    .hero-text h1 {
      font-size: 2rem; /* Adjust font size */
    }
  
    .hero-text p {
      font-size: 1rem; /* Adjust font size */
    }
  }


  .top-service-providers {
    padding: 4rem 0;
    
  }
  
  .top-service-providers h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
    /* color: #fff; */
  }
  
  .provider-card {
    border: none;
    height: 420px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    background-color: #fff;
    color: #333;
  }
  
  .provider-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
  }
  
  .provider-logo {
    height: 220px;
    /* width: 250px; */
    object-fit: contain;
    padding: 1rem;
    border-bottom: 1px solid #f0f0f0;
    background-color: #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .provider-card{
    width: 300px;
  }
  .provider-card .card-body {
    
    padding: 1.5rem;
    text-align: center;
  }
  
  .provider-card .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .provider-card .card-text {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #666;
  }
  
  .provider-card .btn-primary {
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s ease-in-out;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    border-radius: 30px;
    width: 250px;
  }
  
  .provider-card .btn-primary:hover {
    background-color: #0056b3;
  }


  /* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .provider-card .card-title {
    font-size: 1.4rem;
  }

  .provider-card .card-text {
    font-size: 0.9rem;
  }

  .provider-card .btn-primary {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .top-service-providers .container{
    padding: 20px 50px;
  }
  .provider-card .card-title {
    font-size: 1.3rem;
  }

  .provider-card .card-text {
    font-size: 0.85rem;
  }

  .provider-card .btn-primary {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .provider-card .card-title {
    font-size: 1.2rem;
  }

  .provider-card .card-text {
    font-size: 0.8rem;
  }

  .provider-card .btn-primary {
    font-size: 0.8rem;
  }

  .provider-logo{
    height: 150px;
  }
}




  
  
  .banner-section {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .full-width-container {
    position: relative;
  }
  
  .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  .banner-button {
    margin-top: 10px;
  
  }
  
  /* Media Queries for responsive design */
  @media (max-width: 1200px) {
    .banner-section .w-100 {
      width: 100%;
      height: 180px;
      /* height: 280px; */
      padding: 0;
    }
    .banner-content{
      width: 100vw;
      font-size: 1rem;
    }
    .banner-section .banner-content h2 {
      font-size: 1.5rem;
      margin-bottom: 0rem;
    }
    
   
    .banner-section .banner-content p {
    
      font-size: 0.9rem;
      margin-bottom: 0;
      padding: 0;
    }
  
    .banner-section .banner-button {
      font-size: 1rem;
      padding: 5px;
    }
  }
  
  @media (max-width: 992px) {
    /* .banner-content h2 {
      font-size: 1.75rem;
    }
  
    .banner-content p {
      font-size: 0.9rem;
    }
  
    .banner-button {
      font-size: 0.9rem;
    } */
  }
  
  @media (max-width: 768px) {
    /* .banner-content h2 {
      font-size: 1.5rem;
    }
  
    .banner-content p {
      font-size: 0.8rem;
    }
  
    .banner-button {
      font-size: 0.8rem;
    } */
  }
  
  @media (max-width: 576px) {
    /* .banner-content h2 {
      font-size: 1.25rem;
    }
  
    .banner-content p {
      font-size: 0.7rem;
    }
  
    .banner-button {
      font-size: 0.7rem;
    } */
  }
  
  @media screen and (max-width: 400px) { 

    .banner-section .w-100 {
      width: 100%;
      /* height: 220px; */
      /* height: 320px; */
      padding: 0;
    }
    .banner-content h2 {
      font-size: 1rem;
    }
  
    .banner-content p {
      font-size: 0.6rem;
    }
  
    .banner-button {
      font-size: 0.6rem;
    }
  }
  

  .full-width-container img{
    height: 280px;
    width: 100vw;
      }


      .carousel-section{
        margin-top: 20px;
      }
.carousel-section h2{
  text-align: center;
  margin-bottom: 2rem;
  margin-bottom: 0;
  /* font-weight: bold; */
}
      .logo-slider__wrapper {
        overflow: hidden;
        padding: 0px 0 50px 0;
        margin: 0 auto; /* Centering */
        width: 90%; /* Adjusts margin and width dynamically */
        white-space: nowrap;
        position: relative;
      }
      
      /* .logo-slider__wrapper:hover .logo-slider__logos-slide {
        animation-play-state: paused;
      }
       */
      .logo-slider__logos-slide {
        display: inline-block;
        animation: 45s slide infinite linear;
      }
      
      .logo-slider__image {
        width: 200px; /* Base size */
        height: auto; /* Maintain aspect ratio */
        margin: 0 20px; /* Reduced margin for smaller screens */
        transition: transform 0.3s ease;
      }
      
      /* .logo-slider__image:hover {
        transform: scale(1.1); 
      } */
      
      .concentrix {
        width: 250px; /* Adjust for specific logo */
        height: auto;
      }
      
      .finnely {
        width: 200px;
        height: auto;
      }
      
      .aii {
        width: 100px;
        height: auto;
      }
      
      @keyframes slide {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
      }
      
      /* Responsive Breakpoints */
      @media (max-width: 1200px) {
        .logo-slider__image {
          width: 150px;
          margin: 0 15px;
        }
        .concentrix {
          width: 200px;
        }
        .finnely {
          width: 180px;
        }
        .aii {
          width: 90px;
        }
      }
      
      @media (max-width: 768px) {
        .logo-slider__image {
          width: 180px;
          margin: 0 10px;
        }
        .concentrix {
          width: 150px;
        }
        .finnely {
          width: 140px;
        }
        .aii {
          width: 80px;
        }
      }
      
      @media (max-width: 480px) {
        .logo-slider__image {
          width: 100px;
          margin: 0 5px;
        }
        .concentrix {
          width: 120px;
        }
        .finnely {
          width: 100px;
        }
        .aii {
          width: 60px;
        }
      }
        



      /* .concentrix{
        width: 300px;
        height: 60px;
      }

      .finnely{
        width:280px; 
        height: 140px;
      }

      .aii{
        width:120px;
        height:100px;

      } */

      