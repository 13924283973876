.healthcare-ai-blog {
    background-color: black; /* Black background */
    color: white; /* White text color */
    padding: 20px;

    margin: 0 auto; /* Center align on larger screens */
}

.healthcare-ai-blog h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.healthcare-ai-blog h2 {
    font-size: 1.75rem;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: left;
}

.healthcare-ai-blog h3 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.healthcare-ai-blog p {
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .healthcare-ai-blog {
        padding: 15px;
    }

    .healthcare-ai-blog h1 {
        font-size: 1.8rem;
    }

    .healthcare-ai-blog h2,
    .healthcare-ai-blog h3 {
        font-size: 1.4rem;
    }

    .healthcare-ai-blog p {
        font-size: 0.95rem;
    }
}

@media (max-width: 576px) {
    .healthcare-ai-blog {
        padding: 10px 18px;
    }

    .healthcare-ai-blog h1 {
        font-size: 1.5rem;
    }

    .healthcare-ai-blog h2,
    .healthcare-ai-blog h3 {
        font-size: 1.3rem;
    }

    .healthcare-ai-blog p {
        font-size: 0.9rem;
    }
}
