.footer {
  background-color: #000;
  color: #fff;
  padding: 40px 0;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px 0;
}

.footer-section {
  flex: 1;
  max-width: 300px;
  margin: 0 20px;
  min-width: 200px;
}

.footer-section h3 {
  font-size: 24px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.footer-section p, 
.footer-section a {
  font-size: 16px;
  line-height: 1.8;
  color: #fff;
  text-align: justify;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-section.logos {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-section.logos img {
  max-width: 100px;
  transition: transform 0.3s ease;
}

.footer-section.logos img:hover {
  transform: scale(1.1);
}

.footer-bottom {
  margin-top: 30px;
  font-size: 14px;
  border-top: 1px solid #555;
  padding-top: 10px;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    padding: 10px 20px;
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    max-width: 100%;
    margin: 10px 0;
  }

  .footer-section.logos {
    margin-top: 20px;
  }
}
