/* SearchResults.css */

/* Styling for the container */
.searchpage {
    padding: 40px 20px;
  }
  
 .searchpage> h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #343a40;
  }
  
  .searchpage> p {
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.2rem;
    /* color: #6c757d; */
  }
  
  /* Styling for the service cards */
  .service-card {
    cursor: pointer;
    width: 280px;
    height: 500px;
    transition: transform 0.3s, box-shadow 0.3s;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .service-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-icon {
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .service-card .card-body {
    text-align: center;
    padding: 20px;
  }
  
  .service-card .card-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #343a40;
  }
  
  .service-card .card-text {
    color: #6c757d;
    font-size: 1rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 767.98px) {
    .service-card .card-title {
      font-size: 1.2rem;
    }
  
    .service-card .card-text {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 1200px) {
    .service-card .card-title {
      font-size: 1.5rem;
    }
  
    .service-card .card-text {
      font-size: 1.1rem;
    }
  }
  