.top-fifty-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 600px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .icon {
    flex-shrink: 0;
  }
  
  .icon img {
    width: 80px;
    height: 80px;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }
  
  .details h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
  }
  
  .info-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #666;
    font-size: 0.9rem;
  }
  
  .info-row span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .services {
    padding: 0px;
  }
  
  .services h4 {
    margin-top: 0;
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .service {
    display: inline-block;
    padding: 5px 10px;
    background-color: #e0f7fa;
    border: 1px solid #007bff;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 0.85rem;
    color: #007bff;
  }
  
  .content {
    padding: 0 20px 20px 20px;
  }
  
  .info {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 15px;
  }
  
  .website-link {
    display: inline-block;
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .website-link:hover {
    background-color: #0056b3;
  }
  