.about-container {
    position: relative;
    color: white;
    padding: 20px;
  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .content {
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .content p{
    text-align: justify;
   
  }
  
  h1, h2, h3, h4 {
    text-align: center;
    margin-bottom: 20px;
    
  }

  .headingsec{
    padding-top:60px ;
  }
  
  .portfolio, .reasons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0;
  }
  



  .boss{
    display: flex;
   
  }
  .service-card, .reason-card {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    width: 280px;
    text-align: center;
   
  }
  
  .service-card img, .reason-card .icon {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .service-card p, .reason-card p {
    font-size: 0.9em;
    
  }

  .reason-card p{
    text-align: justify;
  }
  
  .additional-points{
    padding: 60px 40px;
  text-align: justify;
}