/* BlogPage.css */

.blog-page {
  background-color: #333; 
  padding: 20px 160px;
  color: #fff; 
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.blog-card {
 background-color: white;
  padding: 20px;
  color:black;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.blog-card h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
