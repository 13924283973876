/* Container styles */
.user-service-form-container {
  width: 100%;
  min-height: calc(100vh - 56px);
  padding: 1rem;
  margin-top: 56px; /* Added to account for fixed navbar */
}

/* Card styles */
.user-service-form-card {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 12px;
  border: none;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 100%;
}

/* Heading styles */
.user-service-form-card h3 {
  margin-bottom: 1.5rem;
  color: #2d3748;
  font-weight: 600;
  font-size: 1.5rem;
}

/* Form group styles */
.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

/* Label styles */
.form-label {
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  display: block;
  width: 100%;
}

/* Required field indicator */
.required {
  color: #e53e3e;
  margin-left: 4px;
}

/* Input field styles */
.form-control {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8fafc;
  box-sizing: border-box;
}

.form-control:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
  border-color: #4299e1;
  background-color: #ffffff;
}

/* Select field styles */
select.form-control {
  width: 100%;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%234a5568' viewBox='0 0 16 16'%3E%3Cpath d='M8 11L3 6h10l-5 5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}

/* Button styles */
.requestDemobutton {
  width: 100%;
  padding: 0.875rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 8px;
  background: linear-gradient(135deg, #4299e1 0%, #3182ce 100%);
  border: none;
  transition: background 0.2s ease;
}

.requestDemobutton:hover {
  background: linear-gradient(135deg, #3182ce 0%, #2c5282 100%);
}

/* Alert styles */
.alert {
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: none;
  width: 100%;
}

.alert-success {
  background-color: #c6f6d5;
  color: #276749;
}

.alert-danger {
  background-color: #fed7d7;
  color: #c53030;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .user-service-form-container {
    padding: 0;
    margin-top: 56px; /* Space for navbar */
  }

  .user-service-form-card {
    border-radius: 0;
    box-shadow: none;
    padding: 1rem;
  }

  .form-control {
    font-size: 16px; /* Prevent zoom on iOS */
    padding: 0.625rem 0.875rem;
  }

  .requestDemobutton {
    padding: 0.75rem 1rem;
  }

  /* Ensure the form stays within viewport */
  .row {
    margin: 0;
  }

  .col-md-6 {
    padding: 0;
  }

  /* Adjust container width */
  .container {
    max-width: 100%;
    padding: 0;
  }
}

/* Ensure proper layout on larger screens */
@media (min-width: 769px) {
  .user-service-form-container {
    padding: 2rem;
  }

  .col-md-6 {
    padding: 0 15px;
  }
}